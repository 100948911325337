<template>
  <div>
    <div class="create-primary padding-top-layout padding-right-layout list-menu">
      <div class="list-menu-title">
        <page-title :title="$t('primary_data_merge.title_page')" />
      </div>
      <div class="listMenus-btn">
        <v-row no-gutters class="form-input-primary">
          <v-col cols="12">
            <div class="mb-3">
              <div class="title-btn title-btn-select-scope">
                <span>{{ $t('primary_data_merge.input_field_title') }} </span>
                <div class="title-require">{{ $t('list_menu.label_essential') }}</div>
              </div>
              <div class="list-input-item">
                <input-field
                  :label="$t('register_reduction.placeholder_input_title')"
                  class="mt-2"
                  v-model="formData.title"
                  :inputValue="formData.title"
                  @blur="handleCheckLengthTitle(formData.title)"
                  @focus="errorMessagesTitle = []"
                  :errorMessages="errorMessagesTitle"
                  :isReadOnly="isView"
                  id="title"
                />
              </div>
            </div>
          </v-col>

          <v-col cols="12">
            <div class="my-3">
              <div class="title-btn title-btn-select-scope">
                <span>{{ $t('primary_data_merge.input_field_email') }} </span>
                <div class="title-require">{{ $t('list_menu.label_essential') }}</div>
              </div>
              <div class="list-input-item">
                <input-field
                  :label="$t('primary_data_merge.placeholder_email')"
                  class="mt-2"
                  v-model="formData.email"
                  @blur="handleCheckEmail"
                  @focus="errorMessagesEmail = []"
                  :errorMessages="errorMessagesEmail"
                  :inputValue="formData.email"
                  :isReadOnly="isEdit"
                  id="email"
                />
              </div>
            </div>
          </v-col>

          <v-col cols="12">
            <div class="title-btn title-btn-select-scope mt-3">
              <span>{{ $t('reduction.label_organization_categories') }} </span>
              <div class="title-require">{{ $t('list_menu.label_essential') }}</div>
            </div>
          </v-col>

          <div class="d-md-flex justify-md-space-between input-select-group">
            <div class="list-input-item organization mb-3 mt-2" v-if="!isLoadingData">
              <v-select
                solo
                flat
                :items="dataList.organizational"
                v-model="formData.organizational"
                item-text="text"
                item-value="id"
                :label="$t('primary_data_merge.placeholder_organization')"
                class="select-type select-default list-menu--select select-item"
                :menu-props="{ contentClass: 'select-menu export-select' }"
                :disabled="isEdit"
                @change="updateData('organizational')"
                id="select-organizational"
              />
            </div>

            <div class="list-input-item company mb-3 mt-2" v-if="!isLoadingData">
              <v-select
                solo
                flat
                return-object
                :items="dataList.companies"
                v-model="formData.company"
                item-text="text"
                item-value="text"
                :label="$t('primary_data_merge.placeholder_company')"
                class="select-type select-default list-menu--select select-item"
                :menu-props="{ contentClass: 'select-menu export-select' }"
                :disabled="isEdit"
                @change="updateData('company')"
                id="select-company"
              />
            </div>
          </div>

          <v-col cols="12" v-if="isHiddenLayer">
            <div class="my-3">
              <div class="title-btn title-btn-select-scope">
                <span>{{ $t('primary_data_merge.input_field_layer') }} </span>
                <div class="title-require">{{ $t('list_menu.label_essential') }}</div>
              </div>
              <template v-for="(data, index) in listLayersVisible">
                <div class="list-input-item mt-2" :key="index" v-if="data.isShowLayer">
                  <v-select
                    solo
                    flat
                    return-object
                    :items="data.items"
                    v-model="data.valueSelected"
                    :item-text="data.key"
                    :item-value="data.key"
                    :label="
                      isEdit && !data.valueSelected
                        ? $t('register_data.checkbox_blank')
                        : $t('primary_data_merge.placeholder_layer', { layerName: data.name })
                    "
                    class="select-type select-db select-default list-menu--select select-item"
                    :menu-props="{ contentClass: 'select-menu export-select' }"
                    :disabled="isEdit"
                    @change="updateData(data.key)"
                    :id="`select-${data.key}`"
                  />
                </div>
              </template>
            </div>
          </v-col>

          <v-col cols="12">
            <div class="my-3">
              <div class="title-btn title-btn-select-scope">
                <span>{{ $t('primary_data_merge.input_field_scope_category') }} </span>
                <div class="title-require">{{ $t('list_menu.label_essential') }}</div>
              </div>
              <div class="filter filter-methods select-scope-category-btn">
                <common-button
                  style="width: 100%"
                  class="listMenu-scope"
                  :class="categorySelected && categorySelected.scope && !isEdit ? 'has-scope' : 'no-scope'"
                  @action="isOpenScopeDialog = true"
                  :label="labelScopeCategory"
                  :disabled="isEdit"
                  v-ripple="false"
                  id="scope_select"
                />
              </div>
            </div>
          </v-col>

          <v-col cols="12">
            <div class="my-3">
              <div class="title-btn title-btn-select-scope">
                <span>{{ $t('list_menu.title_calculation_method') }} </span>
                <div class="title-require">{{ $t('list_menu.label_essential') }}</div>
              </div>
              <div class="filter filter-methods select-scope-category-btn">
                <common-button
                  style="width: 100%"
                  class="listMenu-scope"
                  :class="!!currentSelectedMethod && !isEdit ? 'has-scope' : 'no-scope'"
                  @action="radioDialog = true"
                  :disabled="methodList.length === 0 || isEdit"
                  :label="getLabelMethodName()"
                  v-ripple="false"
                  id="select-method"
                />
              </div>
            </div>
          </v-col>

          <div
            v-if="planType.is_pcaf && formData.scope.cateId === 15"
            class="cat15-radio form-input-primary"
          >
          <div class="listMenu-btn-text">
            <!-- pulldown category type -->
            <v-select
              solo
              flat
              :disabled="!itemSelectPCAF.listMethodType || isEdit"
              :items="itemSelectPCAF.listMethodType"
              v-model="itemSelectPCAF.categoryIdSelect"
              item-text="name"
              item-value="id"
              class="select-type select-item list-menu--select mb-4 select-method"
              :class="{'disabled': currentSelectedMethod > 2}"
              :menu-props="{ contentClass: 'select-menu export-select' }"
              id="select-category"
              :label='$t("pcaf_update.placeholder_category_type_pcaf")'
              @change="handleChangeTypePCaf"
            ></v-select>
            <!-- pulldown data type -->
            <v-select
              :disabled="!itemSelectPCAF.listDataType || isEdit"
              solo
              flat
              :items="itemSelectPCAF.listDataType"
              v-model="itemSelectPCAF.dataTypeIdSelect"
              item-text="name"
              item-value="id"
              :label='$t("pcaf_update.placeholder_data_type_pcaf")'
              :class="{'disabled': !itemSelectPCAF.listDataType}"
              class="select-type select-item list-menu--select mb-4 select-method"
              :menu-props="{ contentClass: 'select-menu export-select' }"
              id="select-data-type"
              @change="handleChangeTypePCaf"
            ></v-select>
          </div>
            <v-radio-group v-model="attribution_factor_setting" :disabled="isEdit" inline class="custome-radio-group radio-group-primary">
              <div class="radio-part">
                <v-radio
                  v-for="(item, i) in attributionFactors"
                  :key="i"
                  :label="`${item.value}`"
                  :value="item.id"
                  :ripple="false"
                >
                  <template slot="label">
                    <div class="label">
                      {{ item.value }}
                      <div v-if="item.id === 1" class="helper-icon mt-1 pl-2">
                        <img
                          :src="getSettingIcon('helper-icon.svg')"
                          @mouseover="changeTooltipImage($event)"
                          @mouseleave="changeTooltipImage($event, 'mouseleave')"
                          alt=""
                        />
                        <div
                          v-if="showTooltip"
                          class="helper-icon tooltip"
                          v-html="$t('setting_pcaf.tooltip_seclect_option_pcaf')"
                        ></div>
                      </div>
                    </div>
                  </template>
                </v-radio>
              </div>
            </v-radio-group>
          </div>

          <template v-if="isShowClassification">
            <InputCustomByMethod
              :scope-and-category="formData.scope"
              :method="currentSelectedMethod"
              :valueEdit="valueCustomByMethod"
              :disabled="isEdit"
              :pcafType="itemSelectPCAF"
              @onUpdateValue="onUpdateValueCustom"
              @onUpdateValidate="onUpdateValidateInputCustom"
              @onUpdateValidateGroupInput="onUpdateValidateInputGroup"
            ></InputCustomByMethod>
          </template>

          <v-col cols="12" v-if="!isHiddenSource">
            <div class="mt-3">
              <div class="title-btn title-btn-select-scope">
                <span>{{ getNameInputMasterDb.name }} </span>
                <div class="title-require">{{ $t('list_menu.label_essential') }}</div>
              </div>
              <div class="listMenu-btn-text mt-2" v-if="!isLoadingData">
                <InfiniteScroll
                  :items="masterDb"
                  :model="formData.masterDb"
                  :item-text="isShowItemName ? 'item_name' : 'source'"
                  itemValue="idType"
                  :label="getNameInputMasterDb.placeholder"
                  :isDisabled="disabledSourcePulldown"
                  :key="masterDbKey"
                  @updateMasterDb="updateMasterDb"
                  id="select-db"
                />
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
      <div class="action-form-link" ref="scrollElement">
        <div class="return__button-responsive" :class="{ 'fixed-button-bottom': isFixedButton }">
          <div class="registration-responsive">
            <common-button
              class="submit-register-btn"
              :loading="isLoadingSubmit"
              :label="$t('primary_data_merge.button_submit')"
              :disabled="validateForm()"
              type="colored"
              @action="submitForm()"
              v-ripple="false"
            />
          </div>
        </div>
      </div>

      <radio-popup
        disabled
        :scope="categorySelected.scope"
        :category="categorySelected.cateId"
        :dialog="radioDialog"
        :method="methodSelectedInPopup"
        :items="methodList"
        :currentSelect.sync="methodSelectedInPopup"
        @close="closePopup"
        @onChange="submitRadioPopup"
      />

      <radio-popup
        :scope="categorySelected.scope"
        :category="categorySelected.cateId"
        :dialog="radioDialogLayer2"
        :items="methodListLayer2"
        :method="methodLayer2SelectedInPopup"
        :currentSelect.sync="methodLayer2SelectedInPopup"
        :isLayer2="true"
        @close="closePopupLayer"
        @onChange="submitRadioLayerPopup"
      />

      <notification-popup
        :dialog="dialogNotification"
        :message="this.$t('primary_data_merge.popup_create_success')"
        @submit="navigateAfterSubmitSuccess"
      />

      <scope-category-popup
        :dialog="isOpenScopeDialog"
        :disabled="true"
        @close="isOpenScopeDialog = false"
        @selectedScope="selectedScope"
      />
      <ErrorPopup :dialog="dialogError" :message="messageError" @submit="dialogError = false" />
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import { ROUTES } from '@/router/constants';
import PageTitle from '@/components/pageTitle/pageTitle';
import CommonButton from '@/components/utils/button.vue';
import NotificationPopup from '@/components/dialogs/notification-popup';
import ErrorPopup from '@/components/dialogs/error-popup.vue';
import InputField from '@/components/products/input/InputField.vue';
import ExportPulldown from '@/components/pulldown/exportReport';
import ScopeCategoryPopup from '@/components/dialogs/scope-category-popup';
import { prepareBranchData } from '@/utils/registerData';
import { getLabelScopeCategory } from '@/utils/scope-category';
import { getListDetailBranch } from '@/api/registerData';
import { createFormPrimary, getFormPrimaryById, updateFormPrimaryById } from '@/api/primaryDataLinked';

import RadioPopup from '@/components/dialogs/radio-popup';
import validate from '@/utils/validate';

import { getBranchId } from '@/concerns/utils/filter-data';

import methodMixin from './method-mixin.js';
import masterDbMixin from './master-db-mixin.js';
import InputCustomByMethod from './ClassificationInput.vue';
import InfiniteScroll from '@/components/pulldown/InfiniteScroll.vue';
import { TEXT_NULL } from '@/constants/dashboard';
import { formatValue, math, formatBigNumber } from '@/concerns/newRegisterData/wijmo.helper';
import { addThoundSandComma } from '@/utils/convertNumber';
import { getDataTypeApi } from '@/api/pcaf';
import { PCAF_CATEGORY_TYPE_METHOD_1, PCAF_CATEGORY_TYPE_METHOD_2 } from '@/constants/pcaf'
import { validateMaximumCharactorOnlyFacility } from '@/utils/validate';

export default {
  components: {
    PageTitle,
    CommonButton,
    NotificationPopup,
    ErrorPopup,
    InputField,
    ExportPulldown,
    ScopeCategoryPopup,
    RadioPopup,
    InputCustomByMethod,
    InfiniteScroll,
  },

  mixins: [methodMixin, masterDbMixin],

  props: {
    typeForm: {
      type: String,
      default: 'create',
    },
  },

  data() {
    return {
      breadcrumb: [
        {
          text: this.$t('list_menu.hyperlink_home'),
          disabled: false,
          href: ROUTES.HOME_DASHBOARD,
        },
        {
          text: this.$t('primary_data_merge.hyperlink_primary_data_merge_screen'),
          disabled: false,
          href: ROUTES.FORM_PRIMARY_DATA_MERGE,
        },
        {
          text: this.$t('primary_data_merge.hyperlink_create_form'),
          disabled: true,
          href: ROUTES.EMISSIONS,
        },
      ],

      formData: {
        title: '',
        email: '',
        organizational: '',
        company: '',
        scope: '',
        masterDb: '',
      },

      titleEdit: '',

      dialogNotification: false,

      isLoading: false,
      labelScopeCategory: this.$t('primary_data_merge.label_scope_category'),

      isFixedButton: true,

      isOpenScopeDialog: false,
      dialogError: false,
      messageError: '',

      categorySelected: {},
      branchData: {},

      dataList: {
        organizational: [],
        companies: [],
        companyBranchIds: [],
      },

      listLayersVisible: [],
      listLayerResponse: [],

      isLoadingData: true,
      errorMessagesEmail: '',
      errorMessagesTitle: '',
      patternId: '',
      valueCustomByMethod: {},
      keyValidateCustom: [],
      isView: false,
      keyFistSelect: '',
      filterIndex: [],
      layerFirstSelect: '',
      filterTypes: [
        'organizational',
        'company',
        'business_name',
        'country',
        'layer_3',
        'layer_4',
        'layer_5',
        'layer_6',
      ],

      attribution_factor_setting: 1,
      showTooltip: false,
      attributionFactors: [
        {
          id: 1,
          value: this.$t('setting_pcaf.radio_attribution_factor_setting_1'),
        },
        {
          id: 0,
          value: this.$t('setting_pcaf.radio_attribution_factor_setting_2'),
        },
      ],
      masterDbKey: 0,
      scope: null,
      category: null,
      patternId: null,
      isLoadingSubmit: false,
      itemSelectPCAF: {
        categoryIdSelect: null,
        dataTypeIdSelect: null,
        listMethodType: null,
        listDataType: null,
      },
    };
  },
  async mounted() {
    await Promise.all([this.updateBreadCrumb(this.breadcrumb), this.handleGetBranchData()]);
    this.defaultData();

    window.addEventListener('scroll', this.handleScroll);

    if (this.isEdit) this.getFormById();
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  computed: {
    ...mapState('userData', ['currentUser']),

    isHiddenSource() {
      // Check null master-db
      // Scope 3, cate 4, method: 3
      // Scope 3, cate 14
      // Scope 3, cate 16

      // Change input name
      // Scope 3, cate 4, method: 1, 5
      // Scope 3, cate 9, method: 1, 2

      let isDisable = false;

      if (this.formData.scope.scope === 3 && this.formData.scope.cateId) {
        if (this.formData.scope.cateId === 14 || (this.formData.scope.cateId === 15 && this.planType?.is_pcaf)) {
          isDisable = true;
        }
        if (this.formData.scope.cateId === 4 && this.currentSelectedMethod === 3) {
          isDisable = true;
        }
        if (this.formData.scope.cateId === 16) {
          isDisable = true;
        }
        return isDisable;
      }
    },

    getNameInputMasterDb() {
      let labelInput = {
        name: this.$t('primary_data_merge.table_energy_type_source'),
        placeholder: this.$t('primary_data_merge.placeholder_master_db'),
      };

      if (this.formData.scope.scope === 3) {
        if (this.formData.scope.cateId === 4 && this.currentSelectedMethod === 1 && this.currentSelectedLayer2 === 5) {
          labelInput = {
            name: this.$t('primary_data_merge.table_energy_type_source'),
            placeholder: this.$t('primary_data_merge.placeholder_master_db'),
          };
        }
        if (this.formData.scope.cateId === 15) {
          labelInput.placeholder = this.$t('primary_data_merge.placeholder_master_db_ghg');
        }
      }

      return labelInput;
    },

    isShowItemName() {
      let isShow = false;
      if (this.formData.scope.scope === 3 && (this.formData.scope.cateId === 4 || this.formData.scope.cateId === 9)) {
        if (this.formData.scope.cateId === 4 && this.currentSelectedLayer2 === 6) {
          isShow = false;
        } else if (this.formData.scope.cateId === 9 && this.currentSelectedLayer2 === 3) {
          isShow = false;
        } else {
          isShow = true;
        }
      }
      if (this.formData.scope.scope === 3 && (this.formData.scope.cateId === 6 || this.formData.scope.cateId === 7)) {
        if (this.currentSelectedMethod === 1 || this.currentSelectedMethod === 2) {
          isShow = true;
        } else {
          isShow = false;
        }
      }
      if (this.formData.scope.scope === 1 && this.currentSelectedMethod) {
        if (this.currentSelectedMethod != 4) {
          isShow = true;
        } else {
          isShow = false;
        }
      }
      if (this.formData.scope.scope === 2) isShow = true;
      if (this.formData.scope.cateId === 15) {
        isShow = true;
      }
      return isShow;
    },

    isEdit() {
      return this.typeForm === 'edit';
    },

    isHiddenLayer() {
      const listLayerShow = this.listLayersVisible.filter((item) => !item.isShowLayer);
      return listLayerShow.length !== this.listLayersVisible.length;
    },

    isShowClassification() {
      const scope = this.formData.scope?.scope;
      const cateId = this.formData.scope.cateId;
      let statusShow = false;

      if (scope === 1 && this.currentSelectedMethod === 4) {
        statusShow = true;
      }

      if (scope === 3) {
        if ([4, 5, 9, 11, 12, 14, 15].includes(cateId)) {
          if ([4, 9, 15].includes(cateId)) {
            if (cateId === 4 && this.currentSelectedMethod === 1 && this.currentSelectedLayer2 === 6) {
              statusShow = true;
            }
            if (cateId === 9 && this.currentSelectedMethod === 1 && this.currentSelectedLayer2 === 3) {
              statusShow = true;
            }
            if (cateId === 15 && this.currentSelectedMethod && !this.planType?.is_pcaf) {
              statusShow = true;
            }

            if(cateId === 15 && this.currentSelectedMethod && this.planType?.is_pcaf && this.itemSelectPCAF.dataTypeIdSelect) {
              if(this.currentSelectedMethod === 1 || this.currentSelectedMethod === 2 && this.itemSelectPCAF.categoryIdSelect) {
                statusShow = true;
              } else if (this.currentSelectedMethod > 2) {
                statusShow = true;
              }
            }
          } else {
            statusShow = true;
          }
        }
      }

      return statusShow;
    },

    isNullAllLayer() {
      const layerSelected = this.listLayersVisible.filter((item) => !!item.valueSelected);
      return !layerSelected.length;
    },

    disabledSourcePulldown() {
      return (
        this.masterDb.length === 0 ||
        this.typeForm === 'edit' ||
        ([4, 9].includes(this.categorySelected.cateId) && (!this.currentSelectedMethod || !this.currentSelectedLayer2))
      );
    },
  },

  methods: {
    ...mapActions('commonApp', ['updateBreadCrumb']),
    checkPCAFOrGHGPlanToRedirect() {
      if (this.scope === 3 && this.category === 15) {
        const isPCAF = this.scope === 3 && this.category === 15 && [5, 6, 7, 8, 9, 10, 11].includes(this.patternId);
        const isGHG = !isPCAF;

        const isRedirectNeeded = (isPCAF && !this.planType?.is_pcaf) || (isGHG && this.planType?.is_pcaf);

        if (isRedirectNeeded) {
          this.$router.push({ path: ROUTES.FORM_PRIMARY_DATA_MERGE });
        }
      }
    },

    handleScroll() {
      const scrollElementTop = this.$refs.scrollElement.offsetTop; // Vị trí của phần tử
      const scrollPosition = window.scrollY + window.innerHeight; // Vị trí scroll hiện tại
      if (scrollPosition > scrollElementTop + 237) {
        //position btn currently
        this.isFixedButton = false;
      } else {
        this.isFixedButton = true;
      }
    },

    handleChangeTypePCaf() {
      this.resetData();
      this.handleScroll();
    },

    getDataTypeByMethod(method) {
      getDataTypeApi(method).then((res) => {
        this.itemSelectPCAF.listDataType = res.data;
      });
    },

    getFormById() {
      getFormPrimaryById(this.$route.params.id).then((res) => {
        let dataForm = res.data;
        this.isView = !dataForm.status;
        this.titleEdit = dataForm.title;
        this.formData = {
          title: dataForm.title,
          email: dataForm.user.email,
          masterDb: `${
            dataForm.db_customize_id || dataForm.db_master_id || dataForm.db_relation?.db_source_detail_id
          }-${dataForm.type}`,
          organizational: dataForm.branch.organizational_division,
          company: dataForm.branch.company_name,
        };
        this.scope = parseInt(dataForm.scope);
        this.category = parseInt(dataForm.category);
        this.patternId = dataForm.pattern_id;
        this.checkPCAFOrGHGPlanToRedirect();
        this.attribution_factor_setting = +dataForm.is_in_approx;
        this.itemSelectPCAF.categoryIdSelect = dataForm.type_classify
        this.itemSelectPCAF.dataTypeIdSelect = dataForm.type_data

        const keyCustemInput = [
          'target_model',
          'fuel',
          'processing_method',
          'scale_index_source',
          'wsu_type',
          'currency',
          'classify_gics',
          'scope_pattern',
          'category_1',
          'category_2',
          'total_capital',
          'dq_quality_score',
          'loan_amount',
          'gdp_ppp_adjusted',
          'ir_calculation_numerator',
          'ir_internal_investment',
          'ir_calculated_denominator',
          'ir_total_investment',
          'ir_company_hold',
          'value_purchase',
          'ir_release_quantity',
          'ides_name_company',
          'product_name',
          'investment_destination',
        ];
        let keyExistValue = keyCustemInput.filter((key) => dataForm[key] !== null);

        keyExistValue.forEach((key) => {
          this.valueCustomByMethod[key] = dataForm[key];
        });

        this.listLayersVisible.map((item) => {
          let data = item;
          data.valueSelected = dataForm.branch[data.key];
          return data;
        });

        this.getMethodType(this.planType?.is_pcaf ? dataForm.pattern_id - 4 : dataForm.pattern_id)

        if (+dataForm.category === 15 && +dataForm.scope === 3) {
          this.getDataTypeByMethod(dataForm.pattern_id);
        }

        this.getHeaderFromScope(+dataForm.scope, +dataForm.category, dataForm.pattern_id);
        this.getMethodList(+dataForm.scope, +dataForm.category);
        this.getMethodListLayer2(+dataForm.scope, +dataForm.category);
        this.selectedScope({ scope: +dataForm.scope, cateId: +dataForm.category });
        if(!this.methodSelectedInPopup) {
          this.methodSelectedInPopup = this.currentSelectedMethod
        }
      });
    },

    submitForm() {
      this.isLoadingSubmit = true;
      if (this.isEdit) {
        this.editForm();
      } else {
        this.createForm();
      }
    },

    editForm() {
      const getInfordb = this.formData.masterDb.split('-');

      let keyNumber = [
        'gdp_ppp_adjusted',
        'loan_amount',
        'total_capital',
        'value_purchase',
        'ir_calculation_numerator',
        'ir_internal_investment',
        'ir_calculated_denominator',
        'ir_total_investment',
        'ir_company_hold',
        'ir_release_quantity',
      ];

      let dataMethod = Object.assign({}, this.valueCustomByMethod);
      keyNumber.forEach((item) => {
        if (dataMethod[item]) {
          dataMethod[item] = formatValue(dataMethod[item]);
        }
      });
      let payload = {
        title: this.formData.title,
        email: this.formData.email,
        db_id: +getInfordb[0] ? +getInfordb[0] : null,
        type: +getInfordb[1],
        ...dataMethod,
      };
      updateFormPrimaryById(this.$route.params.id, payload)
        .then((_) => {
          this.dialogNotification = true;
        })
        .catch((error) => {
          if (error.errors?.email) {
            this.dialogError = true;
            this.messageError = error.errors.email.join('\n');
          }
        })
        .finally(() => {
          this.isLoadingSubmit = false;
        });
    },

    createForm() {
      let getLayer = {};

      this.listLayersVisible.forEach((item) => {
        if (item.valueSelected) {
          getLayer[item.key] = item.valueSelected[item.key];
        }
      });
      const dataSelect = {
        company_name: this.formData.company.text,
        organizational_division: this.formData.organizational,
        ...getLayer,
      };

      this.getPatternIdCurrent();

      const getInfordb = this.formData.masterDb.split('-');
      let keyNumber = [
        'gdp_ppp_adjusted',
        'loan_amount',
        'total_capital',
        'value_purchase',
        'ir_calculation_numerator',
        'ir_internal_investment',
        'ir_calculated_denominator',
        'ir_total_investment',
        'ir_company_hold',
        'ir_release_quantity',
      ];

      let dataMethod = Object.assign({}, this.valueCustomByMethod);
      keyNumber.forEach((item) => {
        if (dataMethod[item]) {
          dataMethod[item] = formatValue(dataMethod[item]);
        }
      });

      let dataCal = {};
      if (this.formData.scope.cateId === 15) {
        dataCal = this.calculationOwn(dataMethod);
      }

      let payload = {
        title: this.formData.title,
        email: this.formData.email,
        branch_id: getBranchId(this.branchData.filterPatternList, dataSelect)?.id || null,
        scope: this.formData.scope.scope,
        category: this.formData.scope.cateId,
        pattern_id: this.patternId,
        db_id: +getInfordb[0] ? +getInfordb[0] : null,
        type: +getInfordb[1],
        ...dataMethod,
        ...dataCal,
      };

      if (this.formData.scope.cateId === 15) {
        payload.is_in_approx = this.attribution_factor_setting;
      }

      if(this.formData.scope.cateId === 15 && this.planType?.is_pcaf) {
        if(this.itemSelectPCAF.categoryIdSelect) {
          payload.type_classify = this.itemSelectPCAF.categoryIdSelect;
        }
        payload.type_data = this.itemSelectPCAF.dataTypeIdSelect;
      }

      createFormPrimary(payload)
        .then((_) => {
          this.dialogNotification = true;
        })
        .catch((error) => {
          if (error.errors?.email) {
            this.dialogError = true;
            this.messageError = error.errors.email.join('\n');
          }
        })
        .finally(() => {
          this.isLoadingSubmit = false;
        });
    },

    calculationOwn(data) {
      let valueCalculated = {};

      if (this.currentSelectedMethod === 1) {
        const ir_release_quantity = math.bignumber(formatValue(data.ir_release_quantity));
        const ir_company_hold = math.bignumber(formatValue(data.ir_company_hold));
        valueCalculated.ir_owned = formatValue(
          addThoundSandComma(
            formatBigNumber(math.evaluate(`${ir_company_hold.toString()} / ${ir_release_quantity.toString()}`), 18),
          ),
        );
      }

      if (this.currentSelectedMethod === 2 || this.currentSelectedMethod === 3) {
        const ir_total_investment = math.bignumber(formatValue(data.ir_total_investment));
        const ir_internal_investment = math.bignumber(formatValue(data.ir_internal_investment));
        const valueCal = formatValue(
          addThoundSandComma(
            formatBigNumber(
              math.evaluate(`${ir_internal_investment.toString()} / ${ir_total_investment.toString()}`),
              18,
            ),
          ),
        );
        if (this.currentSelectedMethod === 2) {
          valueCalculated.ir_investment_on_total = formatValue(valueCal);
        } else {
          valueCalculated.pj_investment_ratio = formatValue(valueCal);
        }
      }

      if (this.currentSelectedMethod === 4) {
        const ir_calculated_denominator = math.bignumber(formatValue(data.ir_calculated_denominator));
        const ir_calculation_numerator = math.bignumber(formatValue(data.ir_calculation_numerator));

        valueCalculated.ir_ratio = formatValue(
          addThoundSandComma(
            formatBigNumber(
              math.evaluate(`${ir_calculation_numerator.toString()} / ${ir_calculated_denominator.toString()}`),
              18,
            ),
          ),
        );
      }

      return valueCalculated;
    },

    getSettingIcon(image) {
      return require(`@/assets/icons/${image}`);
    },

    changeTooltipImage(event, type) {
      if (type === 'mouseleave') {
        event.target.src = this.getSettingIcon('helper-icon.svg');
        event.target.parentElement.style.cursor = 'default';
        this.showTooltip = false;
      } else {
        event.target.src = this.getSettingIcon('helper-icon_active.svg');
        event.target.parentElement.style.cursor = 'pointer';
        this.showTooltip = true;
      }
    },

    validateForm() {
      const validateForm = Object.keys(this.formData).filter((key) => key != 'masterDb' && !this.formData[key]);
      const validateMethod = this.methodList.length === 0 ? true : !!this.currentSelectedMethod;
      const validateEmail = !validate.regexMail.test(this.formData.email);

      // Validate layer
      const layersVisible = this.listLayersVisible.filter((key) => key.isShowLayer);
      const validateLayer =
        layersVisible.length && !this.isEdit
          ? this.listLayersVisible.filter((key) => !!key.valueSelected && key.isShowLayer).length !=
            layersVisible.length
          : false;

      const validateSource = this.isHiddenSource ? false : !this.formData.masterDb;

      const validateEdit = this.formData.title === this.titleEdit && this.isEdit;

      // Validate custom input
      let validateProductName = false;
      if (this.categorySelected.cateId === 11) {
        let string = [];
        if (this.valueCustomByMethod.product_name) {
          string = this.valueCustomByMethod.product_name
            ?.trim()
            .split(',')
            .map((word) => {
              return word.trim().length;
            });
        }
        validateProductName = Math.max(...string) > 255 || Math.min(...string) === 0;
      }

      let inputNotRequired = ['category_1', 'category_2'];
      if ((this.currentSelectedMethod === 3) & (this.categorySelected.cateId === 15))
        inputNotRequired.push('investment_destination');

      let validateCustomInput = false;
      if (this.isShowClassification) {
        if (this.keyValidateCustom.length) {
          this.keyValidateCustom.forEach((item) => {
            if (
              ((typeof this.valueCustomByMethod[item] === 'string' && !this.valueCustomByMethod[item]?.trim()) ||
                !this.valueCustomByMethod[item]) &&
              !inputNotRequired.includes(item)
            ) {
              validateCustomInput = true;
              return;
            }
          });
        }
      }

      let validateDataTypePcaf = false;

      if(this.formData.scope.cateId === 15 && this.planType?.is_pcaf) {
        validateDataTypePcaf = (this.itemSelectPCAF.listMethodType?.length && !this.itemSelectPCAF.categoryIdSelect) || !this.itemSelectPCAF.dataTypeIdSelect
      }

      return (
        validateForm.length !== 0 ||
        !validateMethod ||
        validateEmail ||
        validateLayer ||
        validateSource ||
        validateCustomInput ||
        this.isLoading ||
        this.isView ||
        validateEdit ||
        validateProductName ||
        validateDataTypePcaf
      );
    },

    onUpdateValueCustom(value) {
      this.valueCustomByMethod = value;
    },

    onUpdateValidateInputCustom(validate) {
      this.keyValidateCustom = validate;
    },
    onUpdateValidateInputGroup(validate) {
      this.keyValidateCustom = this.keyValidateCustom.concat(validate);
    },
    navigateAfterSubmitSuccess() {
      this.$router.push({ path: ROUTES.FORM_PRIMARY_DATA_MERGE });
    },

    closePopup() {
      this.$nextTick((_) => (this.methodSelectedInPopup = this.currentSelectedMethod));
      this.radioDialog = false;
    },

    closePopupLayer() {
      if (!this.currentSelectedLayer2) {
        this.currentSelectedMethod = null;
        this.currentSelectedLayer2 = null;
      }
      if (this.tempCurrentSelectedMethod !== this.currentSelectedMethod) {
        this.currentSelectedMethod = this.tempCurrentSelectedMethod;
        this.getMethodListLayer2(this.categorySelected.scope, this.categorySelected.cateId);
      }

      this.$nextTick((_) => {
        this.methodSelectedInPopup = this.currentSelectedMethod;
        this.methodLayer2SelectedInPopup = this.currentSelectedLayer2;
      });
      this.radioDialogLayer2 = false;
    },

    selectedScope(scope) {
      let scopeAndCategory = { cateId: +scope.cateId, scope: +scope.scope };
      this.isOpenScopeDialog = false;
      if (!this.isEdit) {
        if (!this.isHiddenSource) this.isFixedButton = false;
        this.resetData();
        this.currentSelectedMethod = null;
        this.currentSelectedLayer2 = null;
        this.methodSelectedInPopup = null;
        this.methodLayer2SelectedInPopup = null;
      }
      this.categorySelected = scopeAndCategory;
      this.labelScopeCategory = getLabelScopeCategory(scopeAndCategory);
      this.formData.scope = scopeAndCategory;

      this.getMasterDb(scopeAndCategory.scope, scopeAndCategory.cateId);
      this.getMethodList(scopeAndCategory.scope, scopeAndCategory.cateId);
      this.handleScroll();
    },

    resetData() {
      this.formData.masterDb = '';
      this.masterDb = [];
      this.valueCustomByMethod = {};
      this.masterDbKey++;
    },

    async handleGetBranchData() {
      this.isLoadingData = true;
      const response = await getListDetailBranch();
      this.listLayerResponse = response.layer;
      this.branchData = prepareBranchData(response);
    },
    defaultLayerData() {
      this.listLayersVisible = [
        {
          valueSelected: '',
          items: this.branchData.filterPatternList,
          key: 'business_name',
          keyMapFilter: 'business_name',
          isShowLayer: !!this.branchData.business_name.length,
          name: this.listLayerResponse.find((item) => item.layer_field === 'business_name')?.layer_name,
        },
        {
          valueSelected: '',
          items: this.branchData.filterPatternList,
          key: 'country',
          keyMapFilter: 'country',
          isShowLayer: !!this.branchData.country.length,
          name: this.listLayerResponse.find((item) => item.layer_field === 'country')?.layer_name,
        },
        {
          valueSelected: '',
          items: this.branchData.filterPatternList,
          key: 'layer_3',
          keyMapFilter: 'layer3',
          isShowLayer: !!this.branchData.layer_3.length,
          name: this.listLayerResponse.find((item) => item.layer_field === 'layer_3')?.layer_name,
        },
        {
          valueSelected: '',
          items: this.branchData.filterPatternList,
          key: 'layer_4',
          keyMapFilter: 'layer4',
          isShowLayer: !!this.branchData.layer_4.length,
          name: this.listLayerResponse.find((item) => item.layer_field === 'layer_4')?.layer_name,
        },
        {
          valueSelected: '',
          items: this.branchData.filterPatternList,
          key: 'layer_5',
          keyMapFilter: 'layer5',
          isShowLayer: !!this.branchData.layer_5.length,
          name: this.listLayerResponse.find((item) => item.layer_field === 'layer_5')?.layer_name,
        },
        {
          valueSelected: '',
          items: this.branchData.filterPatternList,
          key: 'layer_6',
          keyMapFilter: 'layer6',
          isShowLayer: !!this.branchData.layer_6.length,
          name: this.listLayerResponse.find((item) => item.layer_field === 'layer_6')?.layer_name,
        },
      ];
    },

    defaultData() {
      this.isLoadingData = false;
      this.defaultLayerData();

      this.branchData.filterPatternList.forEach((item) => {
        this.addOrganizational(item);
        this.addCompany(item);
      });
      this.removeDuplicateData(this.dataList.organizational, 'organizational');
      this.removeDuplicateData(this.dataList.companies, 'companies');
    },
    addOrganizational(item) {
      if (this.selectedFirst === 'organizational') return;
      this.dataList.organizational.push({
        id: item.organizational_division,
        text: item.organizational_division_value,
      });
    },
    addCompany(item) {
      if (this.selectedFirst === 'company') return;
      this.dataList.companies.push({
        text: item.company_name,
      });
    },
    addBusiness(item) {
      if (this.selectedFirst === 'business_name') return;
      this.listLayersVisible[0].items.push(item);
    },
    addCountry(item) {
      if (this.selectedFirst === 'country') return;
      this.listLayersVisible[1].items.push(item);
    },
    addLayer3(item) {
      if (this.selectedFirst === 'layer_3') return;
      this.listLayersVisible[2].items.push(item);
    },
    addLayer4(item) {
      if (this.selectedFirst === 'layer_4') return;
      this.listLayersVisible[3].items.push(item);
    },
    addLayer5(item) {
      if (this.selectedFirst === 'layer_5') return;
      this.listLayersVisible[4].items.push(item);
    },
    addLayer6(item) {
      if (this.selectedFirst === 'layer_6') return;
      this.listLayersVisible[5].items.push(item);
    },

    updateData(type) {
      if (this.keyFistSelect === '') this.keyFistSelect = type;
      this.addFilterIndex(type);
      this.updateFilter(type);
    },

    addFilterIndex(type) {
      const existIndex = this.filterIndex.findIndex((item) => {
        return item === type;
      });
      if (existIndex < 0) {
        this.filterIndex.push(type);
      }
    },

    updateFilter(type) {
      if (this.filterIndex.length === 0) {
      }
      this.filterCondition(type);
    },

    filterCondition(type) {
      if (this.filterIndex.length === 0) return;
      this.resetFilter(type);
      this.resetSelectedData(type);
      this.branchData.filterPatternList.forEach((item) => {
        this.filterLayer(type, item);
      });
    },

    resetSelectedData(type) {
      let types = [];
      let filterDataList = [];
      const filterIndexLength =
        this.filterIndex.indexOf(type) < 0 ? this.filterIndex.length - 1 : this.filterIndex.indexOf(type);
      for (let i = 0; i <= filterIndexLength; i++) {
        filterDataList.push(this.filterIndex[i]);
      }
      types = this.filterTypes.filter((item) => !filterDataList.includes(item));
      types.forEach((type) => {
        switch (type) {
          case 'organizational':
            this.dataList.organizational = [];
            this.formData.organizational = '';
            break;
          case 'company':
            this.dataList.companies = [];
            this.formData.company = '';
            break;
          case 'business_name':
            this.listLayersVisible[0].items = [];
            this.listLayersVisible[0].valueSelected = '';
            break;
          case 'country':
            this.listLayersVisible[1].items = [];
            this.listLayersVisible[1].valueSelected = '';
            break;
          case 'layer_3':
            this.listLayersVisible[2].items = [];
            this.listLayersVisible[2].valueSelected = '';
            break;
          case 'layer_4':
            this.listLayersVisible[3].items = [];
            this.listLayersVisible[3].valueSelected = '';
            break;
          case 'layer_5':
            this.listLayersVisible[4].items = [];
            this.listLayersVisible[4].valueSelected = '';
            break;
          case 'layer_6':
            this.listLayersVisible[5].items = [];
            this.listLayersVisible[5].valueSelected = '';
            break;
        }
      });
    },

    resetFilter(type) {
      const index = this.filterIndex.indexOf(type);
      if (index > -1) {
        this.filterIndex.splice(index + 1);
      }
    },

    filterLayer(type, item) {
      let filterDataConditionList = [];
      const filterIndexLength =
        this.filterIndex.indexOf(type) < 0 ? this.filterIndex.length - 1 : this.filterIndex.indexOf(type);
      for (let i = 0; i <= filterIndexLength; i++) {
        filterDataConditionList.push(this.filterIndex[i]);
      }
      for (let i = 0; i <= filterDataConditionList.length - 1; i++) {
        if (!this.filterDataByIndex(this.filterIndex[i], item)) {
          return;
        }
      }
      switch (this.filterIndex[this.filterIndex.indexOf(type)]) {
        case 'organizational':
          if (!this.filterOrganizational(item)) return;
          break;
        case 'company':
          if (!this.filterCompanies(item)) return;
          break;
        case 'business_name':
          if (!this.filterBusiness(item)) return;
          break;
        case 'country':
          if (!this.filterCountries(item)) return;
          break;
        case 'layer_3':
          if (!this.filterListLayer3(item)) return;
          break;
        case 'layer_4':
          if (!this.filterListLayer4(item)) return;
          break;
        case 'layer_5':
          if (!this.filterListLayer5(item)) return;
          break;
        case 'layer_6':
          if (!this.filterListLayer6(item)) return;
          break;
      }
      this.addFilterDataLoop(type, item);
    },

    addFilterDataLoop(type, item) {
      let types = [];
      let filterDataList = [];
      const filterIndexLength =
        this.filterIndex.indexOf(type) < 0 ? this.filterIndex.length - 1 : this.filterIndex.indexOf(type);
      for (let i = 0; i <= filterIndexLength; i++) {
        filterDataList.push(this.filterIndex[i]);
      }
      types = this.filterTypes.filter((item) => !filterDataList.includes(item)); // get elements not in filterTypes
      types.forEach((type) => {
        switch (type) {
          case 'organizational':
            this.addOrganizational(item);
            break;
          case 'company':
            this.addCompany(item);
            break;
          case 'business_name':
            this.addBusiness(item);
            break;
          case 'country':
            this.addCountry(item);
            break;
          case 'layer_3':
            this.addLayer3(item);
            break;
          case 'layer_4':
            this.addLayer4(item);
            break;
          case 'layer_5':
            this.addLayer5(item);
            break;
          case 'layer_6':
            this.addLayer6(item);
            break;
        }
      });
    },

    filterDataByIndex(type, item) {
      switch (type) {
        case 'organizational':
          return this.filterOrganizational(item);
        case 'company':
          return this.filterCompanies(item);
        case 'business_name':
          return this.filterBusiness(item);
        case 'country':
          return this.filterCountries(item);
        case 'layer3':
          return this.filterListLayer3(item);
        case 'layer4':
          return this.filterListLayer4(item);
        case 'layer5':
          return this.filterListLayer5(item);
        case 'layer6':
          return this.filterListLayer6(item);
        default:
          return true;
      }
    },

    filterOrganizational(item) {
      return (
        (this.formData.organizational !== '' && this.formData.organizational === item.organizational_division) ||
        this.formData.organizational === ''
      );
    },

    filterCompanies(item) {
      return (
        (this.formData.company.text !== '' && this.formData.company.text === item.company_name) ||
        this.formData.company.text === ''
      );
    },

    filterBusiness(item) {
      return (
        (this.listLayersVisible[0].valueSelected.business_name !== '' &&
          this.listLayersVisible[0].valueSelected.business_name === item.business_name) ||
        this.listLayersVisible[0].valueSelected.business_name === '' ||
        (this.listLayersVisible[0].valueSelected.business_name === TEXT_NULL && !item.business_name)
      );
    },

    filterCountries(item) {
      return (
        (this.listLayersVisible[1].valueSelected.country !== '' &&
          this.listLayersVisible[1].valueSelected.country === item.country) ||
        this.listLayersVisible[1].valueSelected.country === '' ||
        (this.listLayersVisible[1].valueSelected.country === TEXT_NULL && !item.country)
      );
    },

    filterListLayer3(item) {
      return (
        (this.listLayersVisible[2].valueSelected.layer_3 !== '' &&
          this.listLayersVisible[2].valueSelected.layer_3 === item.layer_3) ||
        this.listLayersVisible[2].valueSelected.layer_3 === '' ||
        (this.listLayersVisible[2].valueSelected.layer_3 === TEXT_NULL && !item.layer_3)
      );
    },
    filterListLayer4(item) {
      return (
        (this.listLayersVisible[3].valueSelected.layer_4 !== '' &&
          this.listLayersVisible[3].valueSelected.layer_4 === item.layer_4) ||
        this.listLayersVisible[3].valueSelected.layer_4 === '' ||
        (this.listLayersVisible[3].valueSelected.layer_4 === TEXT_NULL && !item.layer_4)
      );
    },
    filterListLayer5(item) {
      return (
        (this.listLayersVisible[4].valueSelected.layer_5 !== '' &&
          this.listLayersVisible[4].valueSelected.layer_5 === item.layer_5) ||
        this.listLayersVisible[4].valueSelected.layer_5 === '' ||
        (this.listLayersVisible[4].valueSelected.layer_5 === TEXT_NULL && !item.layer_5)
      );
    },
    filterListLayer6(item) {
      return (
        (this.listLayersVisible[5].valueSelected.layer_6 !== '' &&
          this.listLayersVisible[5].valueSelected.layer_6 === item.layer_6) ||
        this.listLayersVisible[5].valueSelected.layer_6 === '' ||
        (this.listLayersVisible[5].valueSelected.layer_6 === TEXT_NULL && !item.layer_6)
      );
    },

    submitRadioPopup() {
      if (this.currentSelectedMethod && this.currentSelectedMethod != this.methodSelectedInPopup && this.categorySelected.scope === 3 && this.categorySelected.cateId === 15 && this.isFixedButton) {
        this.isFixedButton = false;
      }
      // this.handleScroll();
      this.tempCurrentSelectedMethod = this.currentSelectedMethod;
      this.currentSelectedMethod = this.methodSelectedInPopup;
      this.getMethodListLayer2(this.categorySelected.scope, this.categorySelected.cateId);
      this.radioDialog = false;
      // this.resetData();
      if (this.methodListLayer2.length > 0) {
        this.radioDialogLayer2 = true;
      } else {
        this.currentSelectedLayer2 = null;
        if (this.categorySelected.scope === 3 && this.categorySelected.cateId === 15) {
          this.itemSelectPCAF.dataTypeIdSelect = null;
          this.itemSelectPCAF.categoryIdSelect = null;
          this.getMethodType(this.currentSelectedMethod);
          this.getDataTypeByMethod(this.currentSelectedMethod + 4);
        }
        this.resetData();
        this.getMasterDb(this.categorySelected.scope, this.categorySelected.cateId);
      }
    },

    getMethodType(method) {
      if (method === 1) {
        this.itemSelectPCAF.listMethodType = PCAF_CATEGORY_TYPE_METHOD_1;
      } else if (method === 2) {
        this.itemSelectPCAF.listMethodType = PCAF_CATEGORY_TYPE_METHOD_2;
      } else {
        this.itemSelectPCAF.listMethodType = null;
      }
    },

    submitRadioLayerPopup() {
      this.currentSelectedLayer2 = this.methodLayer2SelectedInPopup;
      this.radioDialogLayer2 = false;
      this.resetData();
      this.getLabelMethodName();
      this.getMasterDb(this.categorySelected.scope, this.categorySelected.cateId);
    },

    handleCheckEmail(email) {
      if (email) {
        if (!validate.regexMail.test(email)) {
          this.errorMessagesEmail.push(this.$t('primary_data_merge.error_format_email'));
        }
      }
    },

    handleCheckLengthTitle(value) {
      if (value.length > 255) {
        this.errorMessagesTitle.push(this.$t('validation.error_maximum_20_charactors', { charactors: '255' }));
      }
    },

    updateMasterDb(item) {
      this.formData.masterDb = item;
    },
    removeDuplicateData(arr, type) {
      this.dataList[type] = [...new Map(arr.map((item) => [item['text'], item])).values()];
    },
  },
};
</script>
<style lang="scss">
.return__button-responsive {
  &.fixed-button-bottom {
    position: fixed !important;
    bottom: 0;
    left: 198px;
    right: 0;
    z-index: 99;
    top: auto !important;
    @media (max-width: $desktop) {
      left: 0;
      width: 100% !important;
      margin-left: 0px !important;
    }
  }
}
.create-primary {
  .theme--light.v-text-field--solo > .v-input__control > .v-input__slot {
    background: $monoOffWhite;
  }

  .v-input.field .v-input__control .v-input__slot {
    box-shadow: 0px 1.237px 1.134px 0px rgba(160, 181, 186, 0.24), 0px 3.127px 2.867px 0px rgba(160, 181, 186, 0.17),
      0px 6.38px 5.848px 0px rgba(160, 181, 186, 0.13), 0px 13.141px 12.046px 0px rgba(160, 181, 186, 0.11),
      0px 36px 33px 0px rgba(160, 181, 186, 0.07) !important;
  }
  .action-form-link {
    height: 72px;
  }
}

.form-input-primary {
  width: 100%;
}

.select-type {
  &.v-input--is-disabled {
    .v-input__slot {
      background: $monoLight !important;

      .v-select__selections {
        .v-select__selection--disabled {
          color: $monoMid !important;
        }
      }

      .v-label {
        position: relative !important;
      }
    }
  }
}

.create-primary {
  .select-method {
    &.disabled {
      .v-input__slot {
        background: #e4e6e7 !important;

        input {
          background: unset !important;
        }
      }
    }
  }
}

.create-primary {
  .cat15-radio {
    .custome-radio-group {
      .v-label {
        color: $monoBlack;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.42px;
      }
      .label {
        word-break: break-word;
        position: relative;
      }
    }
  }
}

.list-input-item {
  .v-text-field.v-text-field--solo .v-label {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.03em;
    color: $monoMid;
  }
}

.v-list-item .v-list-item__title {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.03em;
  color: $monoBlack;
}

.listMenu-scope .v-btn__content {
  height: max-content;
  max-width: 100%;
}

.listMenu-scope .v-btn__content:hover {
  color: $monoWhite !important;
}

.listMenu-scope.no-scope .v-btn__content {
  color: $monoMid;
}

.listMenu-scope.has-scope .v-btn__content {
  color: $monoBlack;
}

.listMenu-scope.has-scope .v-btn__content:hover {
  color: $monoWhite;
}

.listMenu-scope .v-btn__content div {
  word-break: break-word;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.select-type.select-item .v-select__selection {
  color: $monoBlack;
}

.listMenu-btn-text {
  .v-text-field.v-text-field--solo .v-label {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.03em;
    color: $monoMid;
  }
}
.form-input-primary {
  input:placeholder-shown {
    text-overflow: ellipsis;
  }
}
</style>
<style lang="scss" scoped>
.custom-table {
  @media (max-width: $tablet) {
    padding-left: 20px !important;
  }
}

.helper-icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  position: absolute;
  right: -20px;
  pointer-events: visible;

  img {
    z-index: 1;
    width: 20px;
    height: 20px;
    background: $bgLight;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
  }

  &.tooltip {
    display: block;
    justify-content: left;
    background: $monoWhite;
    border-radius: 4px;
    color: $monoBlack;
    margin: 0 auto;
    font-size: 15px;
    padding: 10px;
    height: unset;
    width: 300px;
    max-width: max-content;
    position: absolute;
    right: 0px;
    bottom: 20px;
    z-index: 9999;
    box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
      0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
      0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
  }
}

.create-primary {
  .list-menu-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px;

    width: 100%;

    // height: 40px;
    .return__header {
      display: none;
    }
  }

  .input-select-group {
    width: 100%;
    .organization {
      width: 100%;
    }
    .company {
      width: 100%;
    }
  }

  .list-menu--title {
    width: 100%;
    // font-family: 'Source Han Sans JP';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 28px;
    /* identical to box height, or 187% */
    margin-bottom: 48px;
    letter-spacing: 0.05em;

    /* Mono/Black */

    color: $monoBlack;

    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
  }

  .listMenus-btn {
    display: flex;
    flex-flow: column;
    padding: 15px 20px;
    /* Background/Light */
    background: #e3eded;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;

    .listMenu-icon {
      justify-content: center;
      align-items: center;
      display: flex;
      line-height: 24px;
      padding: 24px 0px;
    }

    .listMenu-btn {
      text-decoration: none;
      display: flex;
      // align-items: center;
      justify-content: space-between;
      cursor: pointer;
      border-radius: 4px;
      margin-top: 8px;
      flex-direction: column;

      // padding: 8px 8px 8px 12px;
      .list-input-item {
        width: 100%;
        align-items: center;

        .v-select {
          width: 100%;
        }
      }

      .cross-line {
        margin: 16px 0;
        text-align: center;
        color: $monoMid;
        font-size: 15px;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: 0.75px;
        height: 28px;
      }
    }

    .listMenu-scope {
      text-transform: capitalize;
      color: $monoMid;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      letter-spacing: 0.03em;
      margin-top: 8px;
      width: 100%;
      height: max-content !important;

      &.has-scope {
        color: $monoBlack;
      }

      &:hover {
        color: $monoWhite;
      }
    }

    .arrow-svg {
      width: 36px;
      height: 48px;
    }

    .title-btn {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-between;
      padding: 0px;
      margin: 0;
      gap: 16px;
      width: 100%;
      height: 24px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.03em;
      color: $monoBlack;

      .title-require {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0px 4px 2px;
        width: auto;
        height: 20px;
        margin-top: 8px;

        background: $goldMid;
        border-radius: 4px;
        color: white;
        font-weight: 500;
        font-size: 11px;
        line-height: 18px;
        text-align: center;
        letter-spacing: 0.03em;
      }
    }

    .filter {
      max-width: 100%;
    }
  }

  .page-title {
    margin-bottom: 50px;
  }

  .v-select.v-text-field input {
    width: 496px;
    height: 40px;
    background: $monoOffWhite;
    box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
      0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
      0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
    border-radius: 4px;

    .v-input__slot {
      border: 1px solid rgba(42, 42, 48, 0.1);
      border-radius: 4px;
      background: $monoOffWhite !important;
    }
  }

  .divider {
    width: 100%;
  }

  .list-menu-registration {
    display: none;
  }

  .title-btn-select-scope {
    margin-bottom: 12px;
  }
}

.return {
  &__button-responsive {
    width: calc(100% + 40px);
    display: flex;
    flex-direction: column;
    background: $bgMid;
    box-shadow: 0px -3.13px 2.86674px rgba(160, 181, 186, 0.165596), 0px -1.24px 1.13351px rgba(160, 181, 186, 0.24);
    //padding-top: 16px;
    position: relative;
    top: 80px;
    margin-left: -20px;
  }
}

.return__header-responsive {
  width: 90%;
  height: 40px;
  background: $monoOffWhite;
  box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
    0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
    0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: auto;

  .list-emission-return-responsive {
    height: 20px;
    padding-right: 8px;

    .return-active {
      display: none;
    }
  }

  &:hover {
    .list-emission-return-responsive {
      .return {
        display: none;
      }

      .return-active {
        display: block;
      }
    }
  }

  &:active {
    .list-emission-return-responsive {
      .return {
        display: block;
      }

      .return-active {
        display: none;
      }
    }
  }
}

.registration-responsive {
  width: 90%;
  margin: auto;
  padding: 16px 0;

  .common-btn {
    width: 100%;
  }
}

.filter-organization {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: space-between;
}

.option-duration {
  display: flex;
  flex-flow: row-reverse !important;
  justify-content: space-between;
  gap: 16px;
}

@include desktop {
  .return__button-responsive {
    max-width: calc(560px);
    margin: auto;
  }

  .input-select-group {
    .organization {
      width: 160px !important;
    }
    .company {
      width: 320px !important;
    }
  }

  .input-elic {
    /* width: 440px; */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0 16px;
    margin-top: 8px;
    position: static;
    width: 496px;
    height: 40px;
    left: 0;
    top: 32px;
    background: $monoWhite;
    box-sizing: border-box;
    box-shadow: inset 0 2px 2px rgb(160 181 186 / 15%), inset 0 2px 6px rgb(160 181 186 / 50%);
    border-radius: 4px;
    flex: none;
    align-self: stretch;
    flex-grow: 0;
    outline: none;
  }

  .create-primary {
    .listMenus-btn {
      display: flex;
      // flex-flow: row;
      flex-wrap: wrap;
      width: 100%;
      max-width: 560px;
      margin: auto;
      padding: 32px 32px 48px 32px;

      .listMenu-btn {
        // margin: 0 12px 24px 12px;
        display: flex;
        flex-flow: column;
        // align-items: center;
        justify-content: center;
        margin-top: 10px;
        padding: unset;

        &:hover {
          cursor: pointer;
        }

        img.listMenu-icon {
          display: flex;
          width: 20px;
          height: 36px;
          transform: rotate(90deg);
          flex: none;
          order: 0;
          flex-grow: 0;
        }

        .input-title {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          padding: 0 16px;
          position: static;
          width: 496px !important;
          height: 40px;
          left: 0;
          top: 32px;
          background: $monoOffWhite;
          box-sizing: border-box;
          box-shadow: inset 0px 2px 2px rgb(160 181 186 / 15%), inset 0px 2px 6px rgb(160 181 186 / 50%);
          border-radius: 4px;
          flex: none;
          align-self: stretch;
          flex-grow: 0;
          margin: 8px 0px;
          outline: none;
        }

        .list-input-item {
          display: flex;
          flex-flow: column;

          // padding: 26px 0 22px;
          .listMenu-text {
            padding-left: 0;
          }
        }

        .listMenu-scope {
          /* Auto layout */
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 7px 16px 9px;

          width: 496px;
          height: 40px;

          /* Mono/OffWhite */

          background: $monoOffWhite;
          box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
            0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
            0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
          border-radius: 4px;

          /* Inside auto layout */
          flex: none;
          order: 0;
          align-self: stretch;
          flex-grow: 0;
        }
      }
    }

    .submit-register-btn {
      // background: $goldMid !important;
      box-shadow: 0px 1.2365612983703613px 1.1335145235061646px 0px rgba(160, 181, 186, 0.24),
        0px 3.12735652923584px 2.866743326187134px 0px rgba(160, 181, 186, 0.17),
        0px 6.379513740539551px 5.8478875160217285px 0px rgba(160, 181, 186, 0.13),
        0px 13.140592575073242px 12.04554271697998px 0px rgba(160, 181, 186, 0.11),
        0px 36px 33px 0px rgba(160, 181, 186, 0.07);
      border-radius: 4px;
      transition: 0.1s ease-out;

      .v-btn__content {
        color: white;
      }
    }

    .list-menu-title {
      height: 40px;
      margin-bottom: 48px;
      width: 100%;
      padding-left: 40px;

      .page-title {
        margin-bottom: 0;
      }

      .return__header {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding: 0 !important;
        width: 161px;

        .list-emission-return {
          height: 20px;

          .return-active {
            display: none;
          }
        }

        &:hover {
          .list-emission-return {
            .return {
              display: none;
            }

            .return-active {
              display: block;
            }
          }
        }

        &:active {
          .list-emission-return {
            .return {
              display: block;
            }

            .return-active {
              display: none;
            }
          }
        }
      }
    }

    .list-menu--title {
      padding-left: 40px;
    }

    .list-menu-registration {
      width: 100%;
      max-width: calc(560px);
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 32px;
      gap: 16px;
      height: 104px;
      background: $bgLight;
      border-top: 1px solid rgba(121, 134, 134, 0.12);
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;

      .common-btn {
        width: 100%;

        .v-btn__content {
          color: $monoWhite;
        }
      }
    }
  }
}
.radio-group-primary {
  .radio-part {
    display: flex;
    align-items: flex-start;
    flex-direction: column !important;
    gap: 16px;
  }
}
</style>

    
  
